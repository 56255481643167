import {FC, useCallback, useRef, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Breadcrumbs, Button, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {ICourseEntity, ILessonEntity} from "@plumeuk/shapeshift-types";
import {useAdminEntity} from "@plumeuk/shapeshift-common/hooks";
import {AdminHeader} from "../../AdminHeader";
import {convertSecondsToHHMMSS} from "../../../../utils";
import {LessonVideoPlayer} from "../../../ModulePage/LessonPage/LessonVideoPlayer";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {Player, PlayerAPI, PlayerConfig, PlayerType, StreamType} from "bitmovin-player/modules/bitmovinplayer-core";
import EngineBitmovinModule from "bitmovin-player/modules/bitmovinplayer-engine-bitmovin";
import MseRendererModule from "bitmovin-player/modules/bitmovinplayer-mserenderer";
import HlsModule from "bitmovin-player/modules/bitmovinplayer-hls";
import DashModule from "bitmovin-player/modules/bitmovinplayer-dash";
import AbrModule from "bitmovin-player/modules/bitmovinplayer-abr";
import XmlModule from "bitmovin-player/modules/bitmovinplayer-xml";
import ContainerTSModule from "bitmovin-player/modules/bitmovinplayer-container-ts";
import ContainerMp4Module from "bitmovin-player/modules/bitmovinplayer-container-mp4";
import SubtitlesModule from "bitmovin-player/modules/bitmovinplayer-subtitles";
import SubtitlesCEA608Module from "bitmovin-player/modules/bitmovinplayer-subtitles-cea608";
import PolyfillModule from "bitmovin-player/modules/bitmovinplayer-polyfill";
import StyleModule from "bitmovin-player/modules/bitmovinplayer-style";
import "bitmovin-player/bitmovinplayer-ui.css";
import {UIFactory} from "bitmovin-player-ui";

interface IStyleProps {
	hasVideo?: boolean
}

const useStyles = makeStyles<IStyleProps>()((theme, {hasVideo}) => ({
	adminLessonLandingPage: {},
	adminBanner: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "auto",
		borderRadius: "5px",
		overflow: "hidden"
	},
	adminBannerTitle: {
		position: "relative",
		fontSize: "34px",
		lineHeight: "41px",
		fontWeight: "600",
		letterSpacing: "0.25px",
		alignSelf: "end",
		margin: "32px 0 20px",
		zIndex: 2
	},
	adminVideo: {
		width: "100%",
		height: "100%"
	},
	adminBackgroundImage: {
		position: "absolute",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		objectFit: "cover"
	},
	contentBox: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.default,
		borderWidth: theme.borderWidth.small,
		borderColor: theme.palette.grey[100],
		borderStyle: "solid",
		borderRadius: theme.borderRadius.small
	},
	titleRow :{
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	hr: {
		borderColor: theme.palette.grey[100]
	}
}));

export const AdminLessonLandingPage: FC = () => {
	const {lessonId, id: _courseId} = useParams();
	const entityId = lessonId ? parseInt(lessonId) : -1;
	const courseId = _courseId ? parseInt(_courseId) : -1;
	const {entity: lesson} = useAdminEntity<ILessonEntity, "lesson">("lesson", entityId, undefined, "en")
	const {entity: course} = useAdminEntity<ICourseEntity, "course">("course", courseId, undefined, "en")
	const videoUrl = lesson?.videoUrl;
	const hasVideo = !!videoUrl;
	const {classes} = useStyles({hasVideo});
	const videoRef = useRef<VideoPlayerRef | null>(null);
	const [player, setPlayer] = useState<PlayerAPI | null>(null);

	const handleVideoReady = useCallback((lesson: ILessonEntity) => {
		if(!process.env.REACT_APP_BITMOVIN_PLAYER_KEY)
			throw new Error("REACT_APP_BITMOVIN_PLAYER_KEY required")

		const playerConfig: PlayerConfig = {
			key: process.env.REACT_APP_BITMOVIN_PLAYER_KEY,
			analytics: {
				key: process.env.REACT_APP_BITMOVIN_ANALYTICS_KEY
			},
			playback: {
				preferredTech: [{player: PlayerType.Html5, streaming: StreamType.Hls}]
			}
		};

		if(!videoRef.current?.bitmovin?.container)
			return;

		const playerSource = {
			hls: lesson.videoUrl,
			poster: lesson.videoThumbnailUrl
		};

		Player.addModule(EngineBitmovinModule);
		Player.addModule(MseRendererModule);
		Player.addModule(HlsModule);
		Player.addModule(XmlModule);
		Player.addModule(DashModule);
		Player.addModule(AbrModule);
		Player.addModule(ContainerTSModule);
		Player.addModule(ContainerMp4Module);
		Player.addModule(SubtitlesModule);
		Player.addModule(SubtitlesCEA608Module);
		Player.addModule(PolyfillModule);
		Player.addModule(StyleModule);

		// Delay player setup for Safari
		setTimeout(() => {
			if(!videoRef.current?.bitmovin?.container)
				return;

			const playerInstance = new Player(videoRef.current.bitmovin?.container, playerConfig);
			UIFactory.buildDefaultUI(playerInstance);
			playerInstance.load(playerSource).then(() => {
				setPlayer(playerInstance);
			}, (e) => {
				// reporting needed?
			});
		}, 100);
	}, [])

	return (
		<Box className={classes.adminLessonLandingPage}>
			<Breadcrumbs separator="/" aria-label="breadcrumb">
				<Link to={"/admin/courses"}><Typography variant="body1">Courses</Typography></Link>
				{course ? <Link to={`/admin/courses/${courseId}`}><Typography variant="body1">{course?.title}</Typography></Link> : null}
				<Link to={`/admin/courses/${courseId}/lessons/${entityId}`}><Typography variant="body1">{lesson?.title}</Typography></Link>
			</Breadcrumbs>
			<Typography variant="h3" className={classes.adminBannerTitle}>
				{lesson?.title}
			</Typography>
			<Box className={classes.adminBanner}>
				{hasVideo &&
					<Box className={classes.adminVideo}>
						<LessonVideoPlayer onReady={() => lesson ? handleVideoReady(lesson) : null} ref={videoRef} lesson={lesson} bitmovinPlayer={player} />
					</Box>
				}
			</Box>
			<Box className={classes.contentBox}>
				<Box className={classes.titleRow}>
					<AdminHeader title="Lesson Information" />
					<Link to="edit"><Button variant="outlined">EDIT LESSON</Button></Link>
				</Box>
				{lesson ? <>
					<Typography variant="body1">{lesson.subtitle}</Typography>
					<hr className={classes.hr}/>
					<Typography variant="body1">Duration: {lesson.videoDuration !== undefined ? convertSecondsToHHMMSS(lesson.videoDuration) : ""}</Typography>
					<Typography variant="body1">Last Modified: {(new Date(lesson.updatedAt)).toLocaleString()}</Typography>
					<Typography variant="body1">Supported Languages: {[...lesson.localizations ?? [], lesson.locale].join(", ")}</Typography>
				</>: null}
			</Box>
		</Box>
	);
}