import {FC, useEffect, useState} from "react";
import {Chip, Box, BoxProps} from "@mui/material";
import dayjs from "dayjs";
import {useApi} from "@plumeuk/shapeshift-identity";
import {DataGridPro, GridColumns, GridToolbar} from "@mui/x-data-grid-pro";
import {IAdminUserTrainingFeed} from "../../../../../types/admin";

interface ICustomProps {
	userId: number
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;

const headCells: GridColumns<IAdminUserTrainingFeed["data"][0]> = ([
	{
		field: "title",
		flex: 1,
		headerName: "Lesson",
		width: 200,
		type: "string"
	},
	{
		field: "_status",
		flex: 1,
		headerName: "Status",
		width: 200,
		type: "string",
		renderCell: (e) => {
			if (!e.value.dueDate) {
				return null
			}

			const tomorrow = new Date();
			tomorrow.setHours(0,0,0,0)
			tomorrow.setDate(tomorrow.getDate() + 1);
			const today = new Date();
			today.setHours(0,0,0,0)
			const dueDate = dayjs(e.value.dueDate)


			if (e.value.dueDate.toLocaleDateString() === today.toLocaleDateString()) {
				return <Chip color="success" label="Due Today" />
			}

			if (dueDate < dayjs(tomorrow)) {
				return <Chip color="error" label="Overdue" />
			}

			return <Chip color="default" label="Upcoming" />
		},
		valueGetter: (params) => ({dueDate: params.row?.dueDate, complete: params.row?.complete})
	},
	{
		field: "dueDate",
		flex: 1,
		headerName: "Due on",
		width: 200,
		type: "date",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "course",
		flex: 1,
		headerName: "Parent Course",
		width: 200,
		type: "string",
		valueGetter: (e) => e.value?.title ?? ""
	}
])

export const UserTrainingFeedTab:FC<IProps> = ({userId, ...baseProps}) => {
	const [rows, getRows] = useApi<IAdminUserTrainingFeed>()
	const [batchActionModal, setBatchActionModal] = useState<{label: string, open: boolean, selected: number[]}>({label: "", open: false, selected: []});

	useEffect(() => {
		getRows(`/api/user/admin/${userId}/trainingfeed`)
	}, [userId])

	return <Box {...baseProps}>
		<Box>
			<DataGridPro<IAdminUserTrainingFeed["data"][0]>
				initialState={{
					sorting: {
						sortModel: [{field: "dueDate", sort: "desc"}]
					}
				}}
				sx={{height: 500}} //>igor to do
				aria-label="training feed admin table"
				loading={rows.isLoading}
				pagination
				rowCount={rows?.data?.totalCount ?? 0}
				// bulkActionOptions={[{label: "Mark as Completed", onSelect: (label, selected) => setBatchActionModal({label, open: true, selected})}]}
				rows={rows?.data?.data ?? []}
				columns={headCells}
				rowsPerPageOptions={[5, 10, 20, 100]}
				components={{Toolbar: GridToolbar}}
			/>
		</Box>
	</Box>
}