import {FC} from "react";
import {Box, BoxProps, Typography} from "@mui/material";
import {AdminHeader} from "../../../../AdminHeader";
import {makeStyles} from "tss-react/mui";
import {ICourseEntity} from "@plumeuk/shapeshift-types";

const useStyles = makeStyles()((theme) => ({
	contentBox: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.default,
		borderWidth: theme.borderWidth.small,
		borderColor: theme.palette.grey[100],
		borderStyle: "solid",
		borderRadius: theme.borderRadius.small
	},
	hr: {
		borderColor: theme.palette.grey[100]
	}
}));

interface ICustomProps {
	courseId: number,
	course?: ICourseEntity
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;


export const OverviewTab:FC<IProps> = ({courseId, course, ...baseProps}) => {
	const {classes} = useStyles();

	return <Box {...baseProps}>
		<Box className={classes.contentBox}>
			<AdminHeader title="Course Overview" />
			{course ? <>
				<Typography variant="body1">{course.description}</Typography>
				<hr className={classes.hr}/>
				<Typography variant="body1">Number of lessons: </Typography>
				<Typography variant="body1">Content Type: {(course).contentType }</Typography>
				<Typography variant="body1">Employees Currently Enrolled: {course.userEnrollments}</Typography>
				<Typography variant="body1">Course Duration: {course.timeEstimateHours}</Typography>
			</>: null}
		</Box>
	</Box>
}
