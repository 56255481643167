import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {AdminSideMenuContent} from "./AdminSideMenuContent";
import {Route, Routes} from "react-router-dom";
import {AdminUsersPage} from "./Pages/Users/AdminUsersPage";
import {Box, ThemeProvider} from "@mui/material";
import {AdminCoursesPage} from "./Pages/Courses/AdminCoursesPage";
import {AdminReportsPage} from "./Pages/AdminReportsPage";
import {AdminSupportPage} from "./Pages/AdminSupportPage";
import {AdminSettingsPage} from "./Pages/Settings/AdminSettingsPage";
import {AdminLessonsPage} from "./Pages/Lessons/AdminLessonsPage";
import {AdminQuizzesPage} from "./Pages/Quizzes/AdminQuizzesPage";
import {adminTheme} from "../../templates/adminTheme";
import {palette} from "../../constants";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		background: "#000"
	},
	content: {
		padding: "32px 28px",
		"&:after": {
			content: "''",
			height: "400px",
			width: "100%",
			position: "absolute",
			zIndex: 0,
			bottom: 0,
			left: 0,
			background: `linear-gradient(0deg, ${palette.orange50} 0%, transparent 100%)`
		}
	},
	tableContainer: {
		marginBottom: "52px",
		position: "relative",
		zIndex: "1"
	}
}));

export const ContentManager: React.FC = () => {
	const {classes} = useStyles();

	return (
		<ThemeProvider theme={adminTheme}>
			<PageBase className={classes.pageBase} sideMenuWidthOverride={"283px"} contentWidth="2000px" sideBarContent={<AdminSideMenuContent />}>
				<Box className={classes.content}>
					<Box className={classes.tableContainer}>
						<Routes>
							<Route index element={<AdminUsersPage />}/>
							<Route path="users/*" element={<AdminUsersPage />} />
							<Route path="courses/*" element={<AdminCoursesPage />} />
							<Route path="lessons/*" element={<AdminLessonsPage />} />
							<Route path="quizzes/*" element={<AdminQuizzesPage />} />
							<Route path="reports" element={<AdminReportsPage />} />
							<Route path="support" element={<AdminSupportPage />} />
							<Route path="settings" element={<AdminSettingsPage />} />
						</Routes>
					</Box>
				</Box>
			</PageBase>
		</ThemeProvider>

	);
}