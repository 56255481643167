import {FC, useEffect, useState} from "react";
import {Chip, Box, BoxProps} from "@mui/material";
import {CheckCircleOutline as Check} from "@mui/icons-material";
import {useApi} from "@plumeuk/shapeshift-identity";
import {DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, GridSelectionModel, GridColumns, GridToolbar} from "@mui/x-data-grid-pro";
import {IAdminUserTrainingFeed} from "../../../../../types/admin";
import {makeStyles} from "tss-react/mui";
import {CustomToolbar} from "../../../../../components/admin/bulkEnrollment/CustomToolbar";
import {AdminBulkUserTrainingCompletedModal} from "../../../../../components/admin/bulkEnrollment/AdminBulkUserTrainingCompletedModal";

interface ICustomProps {
	userId: number
}

type Row = (Omit<IAdminUserTrainingFeed["data"][0], "id"> & {id: string});

export type IProps = Omit<BoxProps, "children"> & ICustomProps;

const useStyles = makeStyles()((theme) => ({
	tableRoot: {
		cursor: "default",
		".MuiChip-root": {
			height: "24px"
		}
	}
}))

const headCells: GridColumns<Row> = ([
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		type: "string",
		hideable: false
	},
	{
		field: "title",
		flex: 1,
		headerName: "Lesson",
		type: "string",
		minWidth: 240
	},
	{
		field: "_status",
		flex: 1,
		headerName: "Status",
		type: "string",
		renderCell: (e) => {
			if (!e.value.dueDate) {
				return null
			}

			const tomorrow = new Date();
			tomorrow.setHours(0,0,0,0)
			tomorrow.setDate(tomorrow.getDate() + 1);
			const today = new Date();
			today.setHours(0,0,0,0)
			const dueDate = new Date(e.value.dueDate)


			if (e.value.dueDate.toLocaleDateString() === today.toLocaleDateString()) {
				return <Chip color="success" label="Due Today" />
			}

			if (dueDate < new Date(tomorrow)) {
				return <Chip color="error" label="Overdue" />
			}

			return <Chip color="default" label="Upcoming" />
		},
		valueGetter: (params) => ({dueDate: params.row?.dueDate, complete: params.row?.complete})
	},
	{
		field: "dueDate",
		flex: 1,
		headerName: "Due on",
		type: "date",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "course",
		flex: 1,
		headerName: "Parent Course",
		type: "string",
		valueGetter: (e) => e.value?.title ?? ""
	}
])

export const UserTrainingFeedTab:FC<IProps> = ({userId, ...baseProps}) => {
	const [{data, isLoading}, getData] = useApi<IAdminUserTrainingFeed>()
	const {classes} = useStyles();
	const [selected, setSelected] = useState<{type: string, id: number, courseSlug: string}[]>([]);
	const [bulkCompletionModalOpen, setBulkCompletionModalOpen] = useState<boolean>(false);
	const [rows, setRows] = useState<Row[]>([])
	const timezone = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)

	useEffect(() => {
		getData({url: `/api/user/admin/${userId}/trainingfeed`, data: {timezone}})
	}, [userId])

	useEffect(() => {
		setRows((data?.data ?? [])?.map(e => ({...e, id: `${e.id}_${e.type}_${e.course.slug}`})))
	}, [data])

	return <Box {...baseProps}>
		<Box>
			<DataGridPro<Row>
				className={classes.tableRoot}
				disableColumnFilter
				aria-label="training feed admin table"
				loading={isLoading}
				pagination
				rowCount={data?.totalCount ?? 0}
				rows={rows}
				columns={headCells}
				rowsPerPageOptions={[5, 10, 20, 100]}
				components={{Toolbar: (): JSX.Element => (<CustomToolbar>
					<Box onClick={() => {setBulkCompletionModalOpen(true)}}><Check /> MARK AS COMPLETED</Box>
				</CustomToolbar>)}}
				checkboxSelection
				autoHeight
				selectionModel={selected.map(e => e.id + "_" + e.type + "_" + e.courseSlug)}
				onSelectionModelChange={(e) =>{
					setSelected(
						e.map((x) => {
							const [id, type, courseSlug] = (x as string).split("_");
							return {id: parseInt(id, 10), type, courseSlug};
						})
					)
				}}
			/>
			<AdminBulkUserTrainingCompletedModal
				userId={userId}
				onClose={(r) => {
					setSelected([]);
					setBulkCompletionModalOpen(false);
					if(r) getData()}}
				open={bulkCompletionModalOpen}
				selected={selected}
			/>
		</Box>
	</Box>
}