import {Box, BoxProps, Typography} from "@mui/material";
import {FC, Fragment} from "react";
import {makeStyles} from "tss-react/mui";
import {Footer} from "./footer";
import {NavBar} from "@plumeuk/shapeshift-common/pageBase";
const logo = "/logo.svg"

const useStyles = makeStyles()((theme) => ({
	restrictedPage: {
		display: "flex",
		minHeight: "100vh",
		background: "#121212"
	},
	container:{
		background: "#121212",
		textAlign: "center",
		minHeight: "100dvh",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	restrictedImage: {
		width: "100%",
		maxWidth: "780px"
	},
	pageTitle: {
		fontSize: "96px",
		fontWeight: "600",
		marginBottom: "32px",
		display: "flex",
		justifyContent: "center",
		textAlign: "center",
		borderBottom: "0px solid",
		marginTop: "-120px",
		letterSpacing: "-1.5px",
		[theme.breakpoints.down("lg")]:{
			fontSize: "60px"
		},
		[theme.breakpoints.down("md")]:{
			marginTop: "-15vw",
			fontSize: "42px"
		}
	},
	pageSubtitle: {
		letterSpacing: "0.15px",
		opacity: "0.8",
		marginBottom: "42px",
		textAlign: "center",
		display: "flex",
		justifyContent: "center"
	},
	buttonContainer: {
		zIndex: 2,
		"&[class*=MuiButton]": {
			background: theme.palette.primary.main,
			color: theme.palette.background.default,
			fontWeight: "600",
			borderRadius: "5px",
			margin: "0 12px",
			padding: "12px 24px",
			height: "40px"
		}
	},
	contentContainer: {
		position: "relative",
		paddingTop: "64px",
		marginBottom: "auto",
		width: "980px",
		maxWidth: "100%",
		aspectRatio: "1.38",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		"& > h1, & > p": {
			zIndex: 2
		}
	},
	navBar: {},
	bgImageContainer: {
		position: "absolute",
		width: "100%",
		height: "100%",
		"& > img":{
			width: "100%",
			height: "auto"
		}
	}
}));

interface ICustomProps {
	header?: JSX.Element,
	title?: string,
	subtitle?: string,
	buttons?: JSX.Element[]
}

export type IProps = ICustomProps & BoxProps;

export const RestrictedPage: FC<IProps> = ({buttons, title, header, subtitle, className}) => {
	const {classes, cx} = useStyles();

	const buttonContainer = (
		<Box className={classes.buttonContainer}>
			{buttons?.map((btn, i) => <Fragment key={i}>{btn}</Fragment>)}
		</Box>
	);

	return (
		<Box className={classes.container}>
			{/*  eslint-disable-next-line */}
			{/* @ts-ignore */}
			<NavBar
				className={classes.navBar}
				logoPath={logo}
				tabs={[]}
				disableSideBar
				componentOverrides={{
					pageBaseProfile: () => <></>
				}} />
			<Box className={classes.contentContainer}>
				<Box className={classes.bgImageContainer}>
					{header}
				</Box>
				{title &&
				<Typography variant="h1" className={classes.pageTitle}>
					{title}
				</Typography>
				}
				{subtitle &&
				<Typography variant="body1" className={classes.pageSubtitle}>
					{subtitle}
				</Typography>
				}
				{buttonContainer}
			</Box>
			<Footer />
		</Box>
	);
};
