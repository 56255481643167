import {FC, useEffect, useState} from "react";
import {Box, BoxProps} from "@mui/material";
import {GridColumns} from "@mui/x-data-grid-pro";
import {APIState, useApi} from "@plumeuk/shapeshift-identity";
import {IAdminUserRecentActivity} from "../../../../../types/admin";
import {AdminEntityPageContainer, AdminFieldContainer, AdminUsersCourses} from "@plumeuk/shapeshift-common/admin";
import {IUserEntity, IUserEntityPayload} from "@plumeuk/shapeshift-types";
import {useNavigate} from "react-router-dom";

interface ICustomProps {
	userId: number
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;

const headCells: GridColumns<IAdminUserRecentActivity["data"][0]> = ([
	{
		field: "title",
		flex: 1,
		headerName: "Lesson",
		width: 200,
		type: "string"
	},
	{
		field: "videoLength",
		flex: 1,
		headerName: "Duration",
		width: 200,
		type: "string",
		renderCell: e => {
			if (!e.value) {
				return null
			}
			const minutes = Math.floor(e.value / 60);
			const seconds = e.value % 60;
			return minutes > 0 ? `${minutes} min ${seconds} sec` : `${seconds} sec`;
		}
	},
	{
		field: "completedAt",
		flex: 1,
		headerName: "Watched on",
		width: 200,
		type: "date",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "courseTitle",
		flex: 1,
		headerName: "Parent Course",
		width: 200,
		type: "string"
	}
])

export const UserEnrollmentTab:FC<IProps> = ({userId, ...baseProps}) => {
	const [batchActionModal, setBatchActionModal] = useState<{label: string, open: boolean, selected: number[]}>({label: "", open: false, selected: []});
	const [rows, getRows] = useApi<IAdminUserRecentActivity>()
	const navigate = useNavigate();


	useEffect(() => {
		getRows(`/api/user/admin/${userId}/recentActivity?pageSize=1000`)
	}, [userId])

	const IUserEntityToIUserEntityPayload = (e: IUserEntity): IUserEntityPayload => {
		const {email, firstname, lastname, id, createdAt, updatedAt} = e
		const payload = {
			email, firstname, lastname, id, createdAt, updatedAt
		}
		return payload;
	}

	const handleOnSaveResponse = (e: APIState<IUserEntity>): void => {

	}

	const handleOnGetResponse = (entityApiResponse: APIState<IUserEntity>): void => {
		if(entityApiResponse.statusCode === 404){
			navigate("../")
		}

		if(!entityApiResponse.data)
			return;

		if(userId && entityApiResponse.data.id !== userId){
			navigate("../" + entityApiResponse.data.id.toString() + "?locale=" + entityApiResponse.data.locale)
		}
	}

	return <Box {...baseProps}>
		<AdminEntityPageContainer<IUserEntity, "users">
			entityId={userId}
			entityToPayload={IUserEntityToIUserEntityPayload}
			template={{}}
			type="users"
			onSaveResponse={handleOnSaveResponse}
			onGetResponse={handleOnGetResponse}
		>
			{({formData, setFormData, entity, locale, files, setFiles}) => <>
				{entity?.id && <AdminFieldContainer required label="Enrollment"><AdminUsersCourses initialData={entity} value={formData.courses} onChange={e => setFormData(prev => ({...prev, courses: e}))}/></AdminFieldContainer>}
			</>}
		</AdminEntityPageContainer>
	</Box>
}
