import {Box, BoxProps, LinearProgress, MenuItem, Typography} from "@mui/material";
import React, {FC, useEffect, useState, isValidElement, cloneElement, ReactNode, useContext} from "react";
import {HistoricNotificationDrawer, NavBar, PageBaseProfile, SideMenu} from "@plumeuk/shapeshift-common/pageBase";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {useLocation, useNavigate} from "react-router-dom";
import {mobileNavBarHeight, palette, sideMenuWidth, toolbarHeight} from "../../constants";
import {IconCourseLibrary} from "../../icons/IconCourseLibrary";
import {IconMyProgress} from "../../icons/IconMyProgress";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import {MobileNavBar} from "./mobileNavBar";
import {ProfileImage} from "./profileImage";
import {PageBaseContext} from "../../contexts/pageBaseContext";
const logo = "/logo.svg"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import {Footer} from "./footer";
import {StatusBar, Style} from "@capacitor/status-bar";
import {IconNotes} from "../../icons/IconNotes";
import {Capacitor} from "@capacitor/core";
import {IconPlayButton} from "../../icons/IconPlayButton";
import {IconCourseVideos} from "../../icons/IconCourseVideos";
import {IconFolder} from "../../icons/IconFolder";

interface ICustomProps {
	children: React.ReactNode,
	contentWidth?: string,
	sideMenuWidthOverride?: string,
	sideBarContent?: ReactNode,
	disableSideBar?: boolean,
	disableFooter?: boolean
}

interface IStyleProps {
	sideMenuOpen: boolean,
	hasNavBar: boolean,
	contentWidth?: string,
	sideMenuWidthOverride?: string,
}

const useStyles = makeStyles<IStyleProps>()((theme, {sideMenuOpen, hasNavBar, contentWidth, sideMenuWidthOverride}) => ({
	pageBase: {
		height: "100%"
	},
	notificationsDrawer: {
		"[class*='notificationDrawerContainer']": {
			backgroundColor: "#191919",
			"[class*='historicNotificationItem']": {
				backgroundColor: "#383838"
			}
		}
	},
	pageBaseProfile: {
		alignItems: "center"
	},
	background: {
		backgroundColor: theme.palette.background.default,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: "-1",
		position: "fixed"
	},
	navBar: {
		borderBottom: palette.midGrey3 + " 1px solid",
		background: palette.black,
		fontWeight: 600,
		paddingTop: "env(safe-area-inset-top)",
		"[class*='toolBar']": {
			height: theme.toolbarHeight
		},
		".NavBarTabs": {
			[theme.breakpoints.down("lg")]:{
				display: "none"
			}
		},
		"[class*='logoContainer']": {
			"& img": {
				display: "block",
				transition: "all .3s ease-in-out"
			},
			"& [class*='navBarLogo']": {
				marginRight: "20px"
			}
		},
		"svg path": {
			fill: theme.palette.common.white
		},
		"[class*='menuIconContainer']": {
			[theme.breakpoints.down("lg")]: {
				display: "none"
			}
		},
		"[class*='bellIconContainer']": {
			background: "none",
			height: toolbarHeight,
			width: "40px",
			margin: 0,
			marginTop: "-1px",
			marginRight: "5px",
			borderRadius: 0,
			opacity: "0.56",
			"svg": {
				position: "relative",
				right: "-3px",
				top: "0px"
			}
		}
	},
	usernameLabelContainer: {
		cursor: "pointer",
		gap: "40px",
		[theme.breakpoints.down("lg")]: {
			padding: "0 10px",
			gap: "10px"
		},
		display: "flex",
		justifyContent: "space-between",
		"& svg": {
			marginRight: "-8px",
			"path":{
				fill: palette.grey60
			}
		}
	},
	usernameLabel: {
		color: palette.grey60,
		fontWeight: 600,
		fontSize: "15px"
	},
	navBarSpacer: {
		width: "100%",
		height: toolbarHeight
	},
	contentContainer: {
		width: sideMenuOpen ? `calc(100% - ${sideMenuWidthOverride || sideMenuWidth})` : "100%",
		position: "absolute",
		overflowX: "hidden",
		transition: "all .3s ease-in-out",
		left: sideMenuOpen ? (sideMenuWidthOverride || sideMenuWidth) : "0px",
		boxSizing: "border-box",
		overflowY: "auto",
		top: hasNavBar ? `calc(${toolbarHeight} + env(safe-area-inset-top))` : "env(safe-area-inset-top)",
		height: hasNavBar ? `calc(100% - ${toolbarHeight} - env(safe-area-inset-top)` : "calc(100% - env(safe-area-inset-top)",
		[theme.breakpoints.down("lg")]: {
			height: `calc(100% - ${mobileNavBarHeight} - ${toolbarHeight} - env(safe-area-inset-top))` //> for mobile nav bar

		}
	},
	sideMenu: {
		position: "fixed",
		"& [class*='moduleTitle']": {
			color: "white"
		}
	},
	content: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		height: "100%",
		minHeight: "100%",
		boxSizing: "border-box",
		width: "100%",
		maxWidth: contentWidth ?? "1000px",
		margin: "auto"
	},
	contentInner: {
		marginBottom: "-10px",
		position: "relative",
		zIndex: "1"
	},
	navIcon: {
		marginTop: "3px",
		maxWidth: "20px",
		display: "inline-block"
	},
	loginBtnContainer: {
		display: "flex",
		gap: "10px",
		"& button":{
			padding: "10.7px 22.9px 10.6px 22.9px",
			width: "123px",
			background: theme.palette.common.white,
			color: theme.palette.primary.main,
			fontSize: "12px",
			"&:hover": {
				background: theme.palette.common.white
			}
		}
	},
	profileAvatar: {
		width: "40px",
		height: "40px",
		padding: "12px 8px 12px 12px",
		background: palette.black,
		margin: 0,
		"& img": {
			objectFit: "cover",
			borderRadius: "100%",
			overflow: "hidden"
		}
	}
}));

type IProps = ICustomProps & Omit<BoxProps, "children">;
type pages = "Training Feed" | "Course Library" | "My Progress" | "Content Manager"| "My Notes";

type routeCallback = {
	[key in pages]: string;
};

const routesMap: routeCallback = {
	"Training Feed": "/training-feed",
	"Course Library": "/course-library",
	"My Progress": "/my-progress",
	"My Notes": "/notes",
	"Content Manager": "/admin"
}

const slimLabels: string[] = ["Feed", "Library", "Progress", "Notes"]

export const PageBase: FC<IProps> = ({sideBarContent, disableSideBar, disableFooter, contentWidth, children, className, sideMenuWidthOverride, ...props}) => {
	const mq = useMq();
	const location = useLocation();
	const [sideMenuOpen, setSideMenuOpen] = useState(!disableSideBar && mq.desktop);
	const {classes, cx} = useStyles({sideMenuOpen, hasNavBar: true, contentWidth, sideMenuWidthOverride});
	const navigate = useNavigate();
	const [tabs, setTabs] = useState<pages[]>([]);
	const {user} = useContext(AuthContext)
	const [pageBaseContext, setPageBaseContext] = useContext(PageBaseContext);
	const [platform] = useState(Capacitor.getPlatform());

	useEffect(() => setSideMenuOpen(!disableSideBar && mq.desktop), [disableSideBar, mq.desktop]);

	//for phone chins
	useEffect(() => {
		if(platform === "web")
			return;
		// Make the status bar overlay the webview
		StatusBar.setOverlaysWebView({overlay: true});
		StatusBar.setStyle({style: Style.Dark});
	}, []);

	let pageSelected: pages | undefined = "Training Feed";
	Object.keys(routesMap).forEach(e => {
		const page = e as pages;
		if(location.pathname.startsWith(routesMap[page]))
			pageSelected = page;
	})
	const handlePageSelect = (page: pages): void => {
		navigate(routesMap[page]);
	}

	const handleContentClick = (): void => {
		if(!mq.desktop && sideMenuOpen) setSideMenuOpen(false);
	}

	const tabIcons = [
		<IconPlayButton key={"icon_" + 1} className={classes.navIcon}/>,
		<IconCourseVideos key={"icon_" + 2} className={classes.navIcon}/>,
		<IconMyProgress key={"icon_" + 3} className={classes.navIcon}/>,
		<IconNotes key={"icon_" + 4} className={classes.navIcon}/>,
		<IconFolder key={"icon_" + 5} className={classes.navIcon}/>
	]

	useEffect(() => {
		const tabs: pages[] = ["Training Feed", "Course Library", "My Progress", "My Notes"];
		if(pageBaseContext.ownedCohort){
			tabs.push("Content Manager");
		}

		setTabs(tabs);
	}, [pageBaseContext.ownedCohort])

	const redirectToAccountPage = (): void => {
		window.location.href = (process.env.REACT_APP_PROPELAUTH_ACCOUNT_SETTINGS_URI ?? "")
	}

	return (
		<Box className={cx(classes.pageBase, className)} {...props}>
			<NavBar<pages>
				className={classes.navBar}
				tabs={tabs}
				icons={tabIcons}
				onBurgerAction={() => setSideMenuOpen(prev => !prev)}
				onPageSelect={handlePageSelect}
				pageSelected={pageSelected}
				onLogoClick={() => navigate("/")}
				logoPath={logo}
				disableSideBar={disableSideBar}
				componentOverrides={{
					pageBaseProfile: (props) => <PageBaseProfile {...props}
						enableNotifications
						className={classes.pageBaseProfile}
						onNotificationAction={() => setPageBaseContext(prev => ({...prev, notificationDrawerOpen: !prev.notificationDrawerOpen}))}
						data={{notificationCount: pageBaseContext.notificationCount}}
						componentOverrides={{
							profileAvatar: ({children, className, ...p}) => <ProfileImage {...p} className={cx(className, classes.profileAvatar)}/>,
							editAccountModal: ({open}) => {if(open) redirectToAccountPage(); return <LinearProgress />},
							usernameLabel: ({className, onClick, ...p}) => <Box onClick={onClick} className={classes.usernameLabelContainer}>
								{mq.mobile ? <Box sx={{width: "50px"}}><MenuIcon /></Box> : <>
									<KeyboardArrowDownIcon/>
								</>}
							</Box>,
							menu: ({logoutAction, accountSettingsAction}) => [
								<MenuItem
									key={"pagebase-setting-btn"}
									data-test-id="pagebase-setting-btn"
									onClick={() => navigate("/settings")}
								>
									Training Preferences
								</MenuItem>,
								<MenuItem
									key={"pagebase-account-setting-btn"}
									data-test-id="pagebase-account-setting-btn"
									onClick={() => accountSettingsAction()}
								>
									My Account
								</MenuItem>,
								<MenuItem
									key={"pagebase-support-btn"}
									data-test-id="pagebase-support-btn"
									onClick={() => window.location.href = ("https://www.buildwitt.com/software/construction-training-software/support")}
								>
									Support
								</MenuItem>,
								<MenuItem
									key={"pagebase-profile-logout-btn"}
									data-test-id="pagebase-profile-logout-btn"
									onClick={logoutAction}
								>
									Log Out
								</MenuItem>
							]
						}}
					/>
				}}
			/>
			<SideMenu
				className={classes.sideMenu}
				open={sideMenuOpen}
				width={sideMenuWidthOverride ?? sideMenuWidth}
			>
				{isValidElement(sideBarContent) ? cloneElement(sideBarContent, {...sideBarContent?.props ?? {}, setSideMenuOpen}) : undefined}
			</SideMenu>
			<HistoricNotificationDrawer
				onRead={(e) => setPageBaseContext(prev => ({...prev, notificationCount: e}))}
				slotProps={{backdrop: {invisible: true}}}
				className={classes.notificationsDrawer}
				toolbarHeight={toolbarHeight}
				onClose={() => setPageBaseContext(prev => ({...prev, notificationDrawerOpen: false}))}
				open={pageBaseContext.notificationDrawerOpen}
			/>
			<Box className={classes.contentContainer} onClick={handleContentClick}>
				<Box className={classes.content}>
					<Box className={classes.contentInner}>
						{children}
					</Box>
					{!disableFooter &&
						<Footer />
					}
				</Box>
			</Box>
			<Box className={classes.background} />
			<MobileNavBar<pages> slimLabels={slimLabels} height={mobileNavBarHeight} routesMap={routesMap} icons={tabIcons} />
		</Box>
	);
};
