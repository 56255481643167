import {FC, useEffect, useState} from "react";
import {Box, BoxProps, LinearProgress, Stack} from "@mui/material";
import {AdminHeader} from "../../../AdminHeader";
import {makeStyles} from "tss-react/mui";
import {DataGridPro, GridColumns, GridToolbar} from "@mui/x-data-grid-pro";
import {IAdminUserActiveCourses} from "../../../../../types/admin";
import {useApi} from "@plumeuk/shapeshift-identity";

const useStyles = makeStyles()((theme) => ({
	progressBar: {
		width:"100%"
	},
	progressBarRoot:{
		width:"100%",
		alignItems:"center",
		gap: theme.spacing(2)
	}
}));

interface ICustomProps {
	userId: number
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;

const headCells: GridColumns<IAdminUserActiveCourses["data"][0]> = ([
	{
		field: "title",
		flex: 1,
		headerName: "Course",
		width: 200,
		type: "string"
	},
	{
		field: "moduleCount",
		flex: 1,
		headerName: "Lessons",
		width: 200,
		type: "string"
	},
	{
		field: "dueOn",
		flex: 1,
		headerName: "Due on",
		width: 200,
		type: "date"
	},
	{
		field: "assignedOn",
		flex: 1,
		headerName: "Assigned on",
		width: 200,
		type: "date"
	},
	{
		field: "contentType",
		flex: 1,
		headerName: "Content Type",
		width: 200,
		type: "string"
	},
	{
		field: "percentCompleted",
		flex: 1,
		headerName: "Course Progress",
		width: 200,
		type: "string",
		renderCell: e => {
			const {classes} = useStyles();
			return <Stack className={classes.progressBarRoot} direction="row"><LinearProgress className={classes.progressBar} variant="determinate" value={e.value} /><span>{`${e.value}%`}</span></Stack>
		}
	}
])

export const UserActiveCoursesTab:FC<IProps> = ({userId, ...baseProps}) => {
	const [rows, getRows] = useApi<IAdminUserActiveCourses>()

	useEffect(() => {
		getRows(`/api/user/admin/${userId}/activeCourses`)
	}, [userId])

	return <Box {...baseProps}>
		<AdminHeader title="User's Active Courses" />
		<Box>
			<DataGridPro<IAdminUserActiveCourses["data"][0]>
				initialState={{
					sorting: {
						sortModel: [{field: "assignedOn", sort: "desc"}]
					}
				}}
				aria-label="admin table"
				loading={rows.isLoading}
				sx={{height: 500}} //>igor to do
				pagination
				rowCount={rows?.data?.totalCount ?? 0}
				rows={rows?.data?.data ?? []}
				columns={headCells}
				rowsPerPageOptions={[5, 10, 20, 100]}
				components={{Toolbar: GridToolbar}}
			/>
		</Box>
	</Box>
}