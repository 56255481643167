import {FC, useEffect, useState} from "react";
import {Box, BoxProps, LinearProgress, Stack, Switch} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {useApi} from "@plumeuk/shapeshift-identity";
import {DataGridPro, GridColumns, GridToolbar} from "@mui/x-data-grid-pro";
import {IPaginatedResponse, IUserCourseEnrollmentStatus} from "@plumeuk/shapeshift-types";
import {Add, Check, Undo} from "@mui/icons-material";
import {AdminBulkEnrollmentModal} from "../../../../../../components/admin/bulkEnrollment/AdminBulkEnrollmentModal";
import {AdminBulkCourseCompletionModal} from "../../../../../../components/admin/bulkEnrollment/AdminBulkCourseCompletionModal";
import {AdminBulkCourseUnenrollModal} from "../../../../../../components/admin/bulkEnrollment/AdminBulkCourseUnenrollmentModal";

const useStyles = makeStyles()((theme) => ({
	progressBar: {
		width:"100%"
	},
	progressBarRoot:{
		width:"100%",
		alignItems:"center",
		gap: theme.spacing(2)
	},
	toolbar: {
		display: "flex",
		"& svg": {
			fontSize: "18px"
		}
	},
	toolbarButton: {
		color: theme.palette.primary.main,
		padding: theme.spacing(1.2, 1),
		cursor: "pointer",
		fontSize: "0.8125rem",
		fontWeight: 600,
		"& svg": {
			top: "3px",
			position: "relative"
		},
		transition: "opacity .3s",
		"&:hover": {
			opacity: .8
		}

	}
}));

interface ICustomProps {
	courseId: number
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;

export const EnrollmentTab:FC<IProps> = ({courseId, ...baseProps}) => {
	const {classes} = useStyles();
	const [rows, getRows] = useApi<IPaginatedResponse<IUserCourseEnrollmentStatus[]>>()
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [bulkEnrollModalOpen, setBulkEnrollModalOpen] = useState<boolean>(false);
	const [bulkUnEnrollModalOpen, setBulkUnEnrollModalOpen] = useState<boolean>(false);
	const [bulkCompletionModalOpen, setBulkCompletionModalOpen] = useState<boolean>(false);
	const [selected, setSelected] = useState<number[]>([]);
	const [selectedSingular, setSelectedSingular] = useState<number>();

	const headCells: GridColumns<IPaginatedResponse<IUserCourseEnrollmentStatus[]>["data"][0]> = ([
		{
			field: "email",
			flex: 1,
			headerName: "User",
			width: 200,
			type: "string"
		},
		{
			field: "enrolled",
			flex: 1,
			headerName: "Enroll",
			width: 200,
			type: "string",
			renderCell: e => {
				return <Switch onClick={(x) => {
					x.stopPropagation();
					if(e.value){
						setBulkUnEnrollModalOpen(true)
					}
					else {
						setBulkEnrollModalOpen(true)
					}
					setSelectedSingular(e.id as number)
				}}
				checked={e.value}/>
			}
		},
		{
			field: "dueOn",
			flex: 1,
			headerName: "Due Date",
			width: 200,
			type: "date"
		},
		{
			field: "enrollmentType",
			flex: 1,
			headerName: "Enrollment Type",
			width: 200,
			type: "date"
		},
		{
			field: "progress",
			flex: 1,
			headerName: "Course Completion",
			width: 200,
			type: "string",
			renderCell: e => {
				const {classes} = useStyles();
				return <Stack className={classes.progressBarRoot} direction="row"><LinearProgress className={classes.progressBar} variant="determinate" value={e.value} /><span>{`${e.value}%`}</span></Stack>
			}
		}
	])


	const CustomToolbar = (): JSX.Element => (
		<Box className={classes.toolbar}>
			<GridToolbar />
			<Box className={classes.toolbarButton} onClick={() => setBulkEnrollModalOpen(true)}><Add /> ENROLL</Box>
			<Box className={classes.toolbarButton} onClick={() => setBulkUnEnrollModalOpen(true)}><Undo /> UNENROLL</Box>
			<Box className={classes.toolbarButton} onClick={() => setBulkCompletionModalOpen(true)}><Check /> COMPLETE</Box>
		</Box>
	);

	useEffect(() => {
		getRows(`/api/strapi-plugin-shapeshift-lms/cohort/course/${courseId}/users/enrollments?pageSize=${pageSize}&page=${page}`)
	}, [courseId, page, pageSize])

	return <Box {...baseProps}>
		<DataGridPro<IPaginatedResponse<IUserCourseEnrollmentStatus[]>["data"][0]>
			initialState={{
				sorting: {
					sortModel: [{field: "assignedOn", sort: "desc"}]
				}
			}}
			disableColumnFilter //> to do
			checkboxSelection
			aria-label="admin table"
			loading={rows.isLoading}
			sx={{height: 500}} //>igor to do
			pagination
			selectionModel={selected}
			onSelectionModelChange={e => setSelected(e as number[])}
			paginationMode="server"
			rowCount={rows?.data?.totalCount ?? 0}
			rows={rows?.data?.data ?? []}
			page={(page - 1)}
			pageSize={pageSize}
			onPageSizeChange={e => setPageSize(e)}
			onPageChange={e => setPage(e + 1)}
			columns={headCells}
			rowsPerPageOptions={[5, 10, 20, 100]}
			components={{Toolbar: CustomToolbar}}
		/>
		<AdminBulkEnrollmentModal courseId={courseId} onClose={(r) =>{ setSelected([]); setSelectedSingular(undefined); setBulkEnrollModalOpen(false); if(r) getRows()}} open={bulkEnrollModalOpen} selected={selectedSingular ? [selectedSingular]: selected} />
		<AdminBulkCourseUnenrollModal courseId={courseId} onClose={(r) =>{ setSelected([]); setSelectedSingular(undefined); setBulkUnEnrollModalOpen(false); if(r) getRows()}} open={bulkUnEnrollModalOpen} selected={selectedSingular ? [selectedSingular]: selected} />
		<AdminBulkCourseCompletionModal courseId={courseId} onClose={(r) =>{setSelected([]); setSelectedSingular(undefined); setBulkCompletionModalOpen(false); if(r) getRows()}} open={bulkCompletionModalOpen} selected={selectedSingular ? [selectedSingular]: selected} />
	</Box>
}