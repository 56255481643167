import {FC} from "react";
import {Box, MenuItem, Select} from "@mui/material";
import {SearchBox, NotesFilterProps} from "@plumeuk/shapeshift-common/v2";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
	filterSelect:{
		minWidth: "250px",
		"& .MuiSelect-select > span": {
			visibility: "visible"
		}
	},
	emptyMenuItem:{}
}));

interface IPropsCustom {
	contentTypes?: string[]
}

export type IProps = NotesFilterProps & IPropsCustom;

const EMPTY_CONTENT_TYPE = "Content Type"

export const NotesFilter:FC<IProps> = ({onChange, searchValue, contentTypes = [], ...baseProps}) => {
	const {classes} = useStyles();
	const filterBy = searchValue?.notesGetAdditionalData?.contentType || EMPTY_CONTENT_TYPE

	return <Box {...baseProps}>
		<SearchBox
			value={searchValue.searchTerm}
			onChange={e => onChange({...searchValue, searchTerm: e.target.value ?? ""})}
		/>
		<Select value={filterBy} className={classes.filterSelect} variant="outlined" onChange={value => onChange({...searchValue, notesGetAdditionalData: value.target.value !== EMPTY_CONTENT_TYPE ? {contentType: value.target.value}: undefined})}>
			<MenuItem value={EMPTY_CONTENT_TYPE}><span className={classes.emptyMenuItem}>All</span></MenuItem>
			{contentTypes.map((item, idx) => {
				return <MenuItem key={idx} value={item}>{item}</MenuItem>
			})}
		</Select>
	</Box>
}