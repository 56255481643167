import {FC} from "react";
import {Box, BoxProps} from "@mui/material";
import {AdminHeader} from "../../../../AdminHeader";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
	contentBox: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.default,
		borderWidth: theme.borderWidth.small,
		borderColor: theme.palette.grey[100],
		borderStyle: "solid",
		borderRadius: theme.borderRadius.small
	}
}));

interface ICustomProps {
	courseId: number
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;


export const QuizzesTab:FC<IProps> = ({courseId, ...baseProps}) => {
	const {classes} = useStyles();

	return <Box {...baseProps}>
		<Box className={classes.contentBox}>
			<AdminHeader title="Course Quizzes" />
		</Box>
	</Box>
}