import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {AdminHeader} from "../ManagerView/AdminHeader";
import {Box, Stack, Switch, Button, Typography, LinearProgress, CircularProgress, ThemeProvider, TableContainer, Paper} from "@mui/material";
import {palette} from "../../constants";
import {useContext, useEffect, useState} from "react";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {deepEqual} from "@plumeuk/shapeshift-common/common";
import PersonIcon from "@mui/icons-material/Person";
import {ISettings} from "../../types/settings";
import {LocaleContext} from "../../contexts/localeContext";
import {adminTheme} from "../../templates/adminTheme";
import {AdminContainer} from "../ManagerView/AdminContainer";

const useStyles = makeStyles()((theme) => ({
	adminSettingsPage: {
		maxWidth: theme.containers.constrained,
		marginLeft: "auto",
		marginRight: "auto",
		"& [class*='menuIcon']": {
			display: "none"
		}
	},
	pageBase: {
		paddingTop: "60px",
		"& [class*='content']": {
			maxWidth: "100%"
		}
	},
	settingInner: {
		maxWidth: "860px",
		paddingLeft: "20px",
		paddingRight: "20px",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "40px"
	},
	adminSettingsWrapper: {
		borderRadius: "8px",
		maxWidth: "860px",
		marginLeft: "auto",
		marginRight: "auto",
		padding: "20px",
		border: "1px solid rgba(255,255,255,0.1)",
		background: palette.greyDark
	},
	adminSettingsSection: {
		marginBottom: theme.spacing(1)
	},
	titleContainer: {
		width: "100%",
		padding: "40px 0 0 0"
	},
	table: {
		width: "100%",
		borderTop: 0
	},
	settingsTile: {
		marginTop: "20px",
		marginBottom: "20px"
	},
	switchContainer: {
		alignItems: "center",
		display: "flex",
		gap: "10px",
		marginTop: "20px"
	},
	switch: {
		width: 28,
		height: 16,
		padding: 0,
		display: "flex",
		"&:active": {
			"& .MuiSwitch-thumb": {
				width: 15
			},
			"& .MuiSwitch-switchBase.Mui-checked": {
				transform: "translateX(9px)"
			}
		},
		"& .MuiSwitch-switchBase": {
			padding: 2,
			"&.Mui-checked": {
				transform: "translateX(12px)",
				color: "#fff",
				"& + .MuiSwitch-track": {
					opacity: 1
				}
			}
		},
		"& .MuiSwitch-thumb": {
			width: 12,
			height: 12,
			borderRadius: 6,
			transition: theme.transitions.create(["width"], {
				duration: 200
			})
		},
		"& .MuiSwitch-track": {
			borderRadius: 16 / 2,
			opacity: 1,
			boxSizing: "border-box"
		}
	},
	btnContainer: {
		paddingTop: "20px",
		gap: "10px",
		display: "flex",
		marginTop: "20px",
		"& button:nth-of-type(2)": {
			backgroundColor: theme.palette.primary.main
		}
	}
}));

export const SettingsPage: React.FC = () => {
	const {classes} = useStyles();
	const [me] = useApi<ISettings>("/api/user/me")
	const [updateUserResponse, updateUser] = useApi<ISettings>()
	const [formData, setFormData] = useState<ISettings>({notifyEmail: false, notifyInApp: false, preferredLanguage: "en", overDueSkilsReminder: false, dailySkillsReminder: false});
	const {notify} = useContext(NotificationContext)
	const changeMade = !deepEqual(formData, me.data);
	const [,setLocale] = useContext(LocaleContext)

	const redirectToAccountPage = (): void => {
		window.location.href = (process.env.REACT_APP_PROPELAUTH_URL + "/account")
	}

	useEffect(() => {
		if(me.data){
			setFormData(me.data)
		}
	}, [me])

	useEffect(() => {
		if(updateUserResponse.statusCode === 200){
			notify("Your details have been updated", "Success", INotificationSeverity.success, 5000)
			setLocale({locale: updateUserResponse.data?.preferredLanguage})
		}
	}, [updateUserResponse])

	const handleUpdate = (): void => {
		if(updateUserResponse.isLoading || !changeMade)
			return;

		updateUser({
			url: "/api/user",
			data: formData,
			method: "PUT"
		})
	}

	return (
		<Box className={classes.adminSettingsPage}>
			<ThemeProvider theme={adminTheme}>
				<PageBase className={classes.pageBase} disableSideBar>
					<AdminContainer size="wide">
						<Box className={classes.settingInner}>
							<AdminHeader title="Training Preferences" />
							{(!me.data || me.isLoading) ? <LinearProgress /> :
								<Box className={classes.adminSettingsWrapper}>
									<Box className={classes.adminSettingsSection}>
										<Typography variant="h3">General settings</Typography>
										<Box className={classes.settingsTile}>
											<Typography variant="h4">Preferred language: </Typography>
											<Box className={classes.switchContainer}>
												<Typography>English</Typography>
												<Switch className={classes.switch} checked={formData.preferredLanguage === "es-419"} onChange={e => setFormData(prev => ({...prev, preferredLanguage: e.target.checked ? "es-419" : "en"}))}/>
												<Typography>Spanish</Typography>
											</Box>
										</Box>
										<Box sx={{display: "none"}}>
											<Typography>Notifications</Typography>
											<Box sx={{display: "flex", gap: "10px"}}>
												<Stack sx={{marginTop: "1px"}} direction="row" spacing={1} alignItems="center">
													<Switch className={classes.switch} checked={formData.notifyInApp} onChange={e => setFormData(prev => ({...prev, notifyInApp: e.target.checked}))}/>
													<Typography>In-app</Typography>
												</Stack>
											</Box>
										</Box>
										<Box sx={{display: "none"}}>
											<Stack sx={{marginTop: "1px"}} direction="row" spacing={1} alignItems="center">
												<Switch className={classes.switch} checked={formData.notifyEmail} onChange={e => setFormData(prev => ({...prev, notifyEmail: e.target.checked}))}/>
												<Typography>Email</Typography>
											</Stack>
										</Box>
										<Box sx={{display: "none"}}>
											<Typography>Automatic reminders</Typography>
											<Stack sx={{marginTop: "1px"}} direction="row" spacing={1} alignItems="center">
												<Switch className={classes.switch} checked={formData.dailySkillsReminder} onChange={e => setFormData(prev => ({...prev, dailySkillsReminder: e.target.checked}))}/>
												<Typography>Daily skills</Typography>
											</Stack>
										</Box>
										<Box style={{display: "none"}}>
											<Stack sx={{marginTop: "1px"}} direction="row" spacing={1} alignItems="center">
												<Switch className={classes.switch} checked={formData.overDueSkilsReminder} onChange={e => setFormData(prev => ({...prev, overDueSkilsReminder: e.target.checked}))}/>
												<Typography>Overdue Training</Typography>
											</Stack>
										</Box>
										<Box onClick={() => {window.location.href = (process.env.REACT_APP_PROPELAUTH_ACCOUNT_SETTINGS_URI ?? "")}} sx={{display: "flex", gap: "10px", cursor: "pointer"}}>
											<PersonIcon color="primary" />
											<Typography>Manage account details</Typography>
										</Box>
										<Box className={classes.btnContainer}>
											<Button disabled={!changeMade} sx={{width: "190px"}} onClick={() => handleUpdate()}>
												{updateUserResponse.isLoading ? <CircularProgress /> : "Save preferences"}
											</Button>
											{me.data && <Button disabled={!changeMade} onClick={() => me.data && setFormData(me.data)}>Cancel changes</Button>}
										</Box>
									</Box>
								</Box>
							}
						</Box>
					</AdminContainer>
				</PageBase>
			</ThemeProvider>
		</Box>
	);
}